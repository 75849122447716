.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #999999;
}
.date {
  color: #0166ff;
  margin-right: 10px;
}

.icon {
  cursor: pointer;
  color: #999999;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 23px;
}

.text {
  margin-right: 15px;
  margin-bottom: 3px;
}