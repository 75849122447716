.login-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: transparent no-repeat url('../../assets/material/imgs/login_bg.png') center
  center;
  background-size: cover;
}

.login-container {
  background: #ffffff no-repeat url('../../assets/material/imgs/login_form_bg.png')
  left center;
  background-size: contain;
  border-radius: 10px;
  width: 800px;
  height: 470px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 99;
}

.login-main {
  width: 330px;
  margin-right: 20px;
  margin-top: 50px;
}

.title {
  text-align: center;
  margin-bottom: 50px;
}

h1 {
  letter-spacing: 5px;
  color: #191616;
  font-size: 32px;
  margin-bottom: 0;
}

p {
  text-transform: uppercase;
  color: #999999;
  font-size: 8px;
}

.login-form {
  width: 100%;
}

.site-form-item-icon {
  font-size: 24px;
  margin-right: 10px;
}

.ant-input-affix-wrapper {
  border: none;
  border-bottom: 1px solid #999999;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
  border-color: #999999;
}

.ant-input {
  font-size: 16px;
}

.register {
  margin-top: 12px;
  margin-bottom: 24px;
  color: #333333 !important;
}

.login-btn {
  width: 320px;
  height: 50px;
  color: #ffffff;
  background: linear-gradient(90deg, #356df6, #695ded);
  border-radius: 30px;
  border: none;
  font-size: 22px;
  transition: none;
}

.login-type {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.login-type-icon {
  font-size: 40px;
  color: #666;
}

.triangle {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  font-size: 0;
  border-style: solid;
  border-color: transparent;
  border-left-color: #ffffff;
  border-width: 30px 0 0 30px;
}

.qrcode {
  text-align: center;
}
.qrcode-icon {
  color: #666;
  font-size: 200px;
}
