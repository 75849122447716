.bottom{
    background:linear-gradient(to right,#35BDEF, #2073E9)
}
.bottomFont1{
    font-size: 14px;
    color: #ffffff;
}
.bottomFonts1{
    font-size: 14px;
    color: #858FAA;
}
.bottomFont2{
    font-size: 14px;
    color: #858FAA;
}
.input .ant-input{
    line-height: 35px;
    border-radius: 8px;
}
.input .ant-input::-webkit-input-placeholder{
    color:#83B9FD;
}
.input .ant-btn{
    color:#83B9FD;
    border-radius:8px;
    width: 100px;
    height: 35px;
}
.bottomA{
    margin-left: 20px;
}
.bottom-line{
    background:linear-gradient(#04061D, #04061D);
    margin-top: 50px;
    padding-bottom: 25px;
    padding-top: 25px;
}