.titles {
  font-size: 42px;
  font-family: FZXiaoBiaoSong-B05S;
  font-weight: 400;
  color: #00529C;
}

.titles-English {
  margin-top:-30px;
  font-size: 23px;
  font-family: Adobe Heiti Std;
  color: #292929;
}

/* 针对屏幕分辨率大于等于1200的,小于1600 */
@media (min-width: 1600px) and (max-width:1919px) {
  .titles {
    font-size: 32px;
    font-family: FZXiaoBiaoSong-B05S;
    font-weight: 400;
    color: #00529C;
  }

  .titles-English {
    margin-top:-30px;
    font-size: 23px;
    font-family: Adobe Heiti Std;
    color: #292929;
  }
}

@media (min-width: 1025px) and (max-width:1599px) {
  .titles {
    font-size: 29px;
    font-family: FZXiaoBiaoSong-B05S;
    font-weight: 400;
    color: #00529C;
  }

  .titles-English {
    margin-top:-20px;
    font-size: 20px;
    font-family: Adobe Heiti Std;
    color: #292929;
  }
}

@media (max-width:1024px){
  .titles {
    font-size: 27px;
    font-family: FZXiaoBiaoSong-B05S;
    font-weight: 400;
    color: #00529C;
  }

  .titles-English {
    margin-top:-30px;
    font-size: 23px;
    font-family: Adobe Heiti Std;
    color: #292929;
  }
}