.menu-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menu-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #0066CC;
}

.menu-headers {
  border-right: none;
  flex: 1 1 0;
  overflow: hidden auto;
}

.logo{
  width: 190px;
  margin: 6px;
}

.menu-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-top: 15px;
}

.menu-footer {
  width: 100%;
  text-align: center;
}

.logout-btn {
  color: #999999;
  margin: 10px auto;
  cursor: pointer;
}

.logout-icon {
  font-size: 20px;
  transform: rotate(-90deg);
  color: #999999;
  margin-right: 10px;
  vertical-align: bottom;
}


