.sidebar {
  height: 100vh;
  background-color: #ffffff !important;
  /*position: fixed ;*/
}

.site-layout{
  height: 100%;
}

.site-layout .header {
  background: #fff;
}

.content {
  padding: 16px;
}
