.container {
  padding: 20px 0;
  position: relative;
}

.logo {
  width: 250px;
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav a {
  font-size: 1.5em;
  margin-right: 1em;
  color: #00529c;
  padding-bottom: 5px;
}

.nav a:hover {
  color: #00529c;
}

.nav a.active {
  border-bottom: 3px solid #00529c;
}

.subMenu {
  width: 100%;
  background-color: #00529c;
  position: absolute;
  top: 100%;
  text-align: center;
  z-index: 1;
  animation: fadeIn 0.5s alternate;
}

@keyframes fadeIn {
  0% {
    top: 95%;
    height: 0;
  }
  50% {
    height: auto;
  }
  100% {
    top: 100%;
  }
}

.subMenu a.active {
  border-bottom: 2px solid #ffffff;
}

.subMenu a {
  color: #ffffff;
  font-size: 20px;
  display: inline-block;
  margin: 10px;
}

.subMenu a:hover {
  color: #ffffff;
}
